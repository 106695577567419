<template>
  <div :class="['addressList', isSelection ? 'selectionAddressList' : '']">
    <ul class="list">
      <li
        :class="['item', selectedId == item.URId ? 'selected' : '']"
        v-for="item in addressList"
        :key="item.URId"
        @click="getEditAddress(item)"
      >
        <img
          class="default"
          v-if="item.IsDefult == 1"
          src="~assets/img/shippingAddress/icon_label@2x.png"
        />
        <div class="itemLeft">
          <section class="userBox">
            <p class="userName">{{ item.ReceivingName }}</p>
            <p class="userPhone">{{ item.PhoneNumber }}</p>
          </section>
          <!-- 邮编 -->
          <p class="postcode">
            {{ $t.address.postcode }}：{{ item.PostalCode }}
          </p>
          <p class="address">{{ item.ReceivingAddress }}</p>
        </div>
        <figure class="itemRight">
          <div class="actionBox" v-if="!isSelection">
            <van-icon size="20" name="arrow" />
          </div>
          <div
            class="actionBox"
            v-if="isSelection"
            @click.stop="editAddress(item)"
          >
            <van-icon size="20" name="edit" />
          </div>
        </figure>
      </li>
      <!-- <li class="item">
        <div class="itemLeft">
          <section class="userBox">
            <p class="userName">很多时候放的</p>
            <p class="userPhone">13220025669</p>
          </section>
           邮编
          <p class="postcode">邮编：45457</p>
          <p class="address">的时候都会得到复旦复华时间段复合大师收到回复可接受的收到货很费劲红烧豆腐较好的发的</p>
        </div>
        <figure class="itemRight">
          <img class="goIngo"
               src="~assets/img/shippingAddress/icon_address_right@2x.png">
        </figure>
      </li>-->
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Icon } from "vant";

Vue.use(Icon);
export default {
  name: "AddressList",
  props: ["addressList", "isSelection", "selectedId"],
  data() {
    return {};
  },
  methods: {
    getEditAddress(item) {
      this.$emit("addressDidTap", item);
    },
    editAddress(item) {
      this.$emit("editAddress", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicP {
  font-size: 13px;
  color: #333333;
  margin-top: 12px;
}
.selectionAddressList {
  margin-bottom: 25vh !important;
}
.addressList {
  width: 92%;
  margin: 0 auto;
  margin-bottom: 15vh;
  * {
    margin: 0;
    padding: 0;
  }
  .list {
    width: 100%;
    .selected {
      border: 1px solid #ff5852;
    }
    .item {
      overflow: hidden;
      position: relative;
      width: 100%;
      margin-top: 15px;
      box-sizing: border-box;
      padding: 0 0 0 25px;
      -webkit-box-sizing: border-box;
      background-color: #ffffff;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      border-radius: 8px;
      @include publicFlex;
      .default {
        position: absolute;
        left: -2px;
        top: -2px;
        border-top-left-radius: 10px;
        width: 50px;
      }
      .itemLeft {
        min-width: 75%;
        max-width: 75%;
        .userBox {
          padding-top: 20px;
          @include publicFlex;
          .userName {
            font-size: 15px;
            color: #000000;
            font-weight: bold;
          }
          .userPhone {
            font-size: 13px;
            color: #a7a7a7;
            margin-left: 22px;
            flex: 1;
            -wbkit-flex: 1;
          }
        }
        .postcode {
          @include publicP;
        }
        .address {
          @include publicP;
          padding-bottom: 20px;
        }
      }
      .itemRight {
        min-width: 20%;
        text-align: right;
        .actionBox {
          margin: 1rem 0;
          color: #999;
          text-align: center;
        }
      }
    }
  }
}
</style>

<template>
  <div class="shippingAddress">
    <address-list
      :addressList="addressList"
      :isSelection="isSelection"
      :selectedId="selectedId"
      @addressDidTap="actionAddressDidTap"
      @editAddress="editAddress"
    ></address-list>
    <div :class="['addressBox', isSelection ? 'selectionBox' : '']">
      <div class="addressBt" @click="addAddress">
        {{ $t.address.addAddress }}
      </div>
    </div>
    <placeholder v-if="isPlaceholder"></placeholder>
  </div>
</template>
<script>
import AddressList from "./components/AddressList";
import Placeholder from "@/components/common/Placeholder";
export default {
  name: "ShippingAddress",
  props: {
    isSelection: {
      type: Boolean,
      default: false,
    },
    selectedId: {
      type: Number,
      default: null,
    },
  },
  components: {
    AddressList,
    Placeholder,
  },
  data() {
    return {
      addressList: [],
      isPlaceholder: false,
    };
  },
  mounted() {
    this.loadUsersReceiving();
  },
  methods: {
    loadUsersReceiving() {
      this.$commonMethod.showLoading();
      let param = {
        PageSize: 100,
        PageIndex: 1,
      };
      this.$api.user
        .loadUsersReceiving(param)
        .then((res) => {
          const data = res.data;
          this.addressList = data;
          if (data == null || data === "" || data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.isfirst = false;
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 新建收货地址
    addAddress() {
      if (this.isSelection) {
        this.$emit("addAddress");
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "user/editAddress",
          name: "editAddress",
          query: [],
        });
      }
    },
    actionAddressDidTap(address) {
      if (this.isSelection) {
        this.$emit("selectedAddress", address);
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: `user/editAddress/${address.URId}`,
          name: "EditAddress",
          query: [],
        });
      }
    },
    editAddress(address) {
      if (this.isSelection) {
        this.$emit("editAddress", address);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.shippingAddress {
  width: 100%;
  .selectionBox {
    position: absolute !important;
    bottom: calc(0px + env(safe-area-inset-bottom)) !important;
  }
  .addressBox {
    width: 100%;
    position: fixed;
    background-color: #f6f6f6;
    left: 0;
    bottom: 0;
    z-index: 10;
    padding-top: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    border-top: 1px solid #e5e5e5;
    .addressBt {
      position: relative;
      margin: 0 auto;
      border-radius: 25px;
      background: linear-gradient(left, #71d283, #01aaa3);
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 40px;
      width: 92%;
    }
    .editBt {
      position: relative;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      border-radius: 25px;
      border: 1px solid #25b9ff;
      background: #25b9ff;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 40px;
      width: 92%;
    }
  }
}
</style>
